// jQuery
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// jQuery UI
require('jquery-ui');

// Moment
import moment from 'moment';

// DevExtreme
require('../../node_modules/devextreme-dist/js/dx.all.js');
import deMessages from 'devextreme/localization/messages/de.json';
DevExpress.localization.loadMessages(deMessages);
require('../../node_modules/devextreme-dist/css/dx.common.css')

// Foundation
require('../node_modules/foundation-sites/dist/js/foundation.js');
require('../node_modules/foundation-contextmenu/dist/foundation.contextMenu.js');
require('../node_modules/foundation-contextmenu/dist/foundation.contextMenu.css');